import {PathComponent} from "index";
import {PageFragment, PageFragmentProps, PageFragmentState} from "../shared/PageFragment";
import React, {ReactElement} from "react";
import {
  StyledBoxColumn,
  StyledBoxRow,
  StyledContainer,
  StyledEmpty,
  StyledListItem,
  StyledSpan
} from "../shared/StyledComponents";
import {ButtonBase, Card, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {
  Favorite,
  Favorites,
  Settings,
  Station,
  StationBase,
  StationGroup,
  Stations,
  Tab,
  TABS_KTM,
  TOP_PICKS_STATIONS
} from "./types";
import {BORDER_RADIUS, DW_XS, PD_LG, PD_MD, PD_SM, PD_XLG, PD_XSM, SZ_SM} from "../shared/dimens";
import {
  FavoriteBorderOutlined,
  FavoriteOutlined,
  InfoOutlined,
  PlayCircleOutlineOutlined,
  RadioOutlined,
  StopCircleOutlined
} from "@mui/icons-material";
import {App} from "./App";
import {ListItemChange, OnListItemsListener, UserCache, UserProfilePhoto} from "../shared/types";
import {UserFragment} from "../shared/UserFragment";
import {getAuth} from "@firebase/auth";
import {User} from "../shared/entities";
import {BaseApp} from "../shared/BaseApp";
import {colorEarthRed} from "../shared/colors";
import {SearchFragment, SearchFragmentListener} from "./SearchFragment";
import {FormGenContainer} from "../shared/FormGenContainer";
import {ONE_HOUR_IN_SECONDS, SECOND_IN_MILLIS} from "../shared/date_util";

export type MainProps = PageFragmentProps & {}

type MainState = PageFragmentState & {
  user: User,
  favoriteStations?: Station[],
  selectedTab: Tab,
  selectedStation?: Station,
  playing?: boolean,
  favorite?: boolean,
}

function StationImage(props: { station: StationBase, style: any }) {
  const defaultImage = "/images/default_station.png";
  return <img src={props.station?.image || defaultImage}
              onError={event => {
                if (defaultImage) {
                  (event.target as HTMLImageElement).src = defaultImage;
                }
              }}
              style={props.style}/>
}

function TabView(props: { tabKey: string, selected?: boolean }) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const metadata = TABS_KTM.findMetadata(props.tabKey);
  return <>
    {md
      ? <Typography variant="h5">{metadata.title}</Typography>
      : <metadata.iconType style={{width: 32, height: 32}}/>}
    {props.selected
      ?
      <div style={{position: "absolute", width: "100%", height: 4, bottom: 0, background: metadata.color}}/>
      : null}
  </>;
}

function Logo(): ReactElement {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <img src={md ? "/stamp.png" : "/logo192.png"} style={{height: 48, alignSelf: "center"}}/>;
}

export class Main extends PageFragment<MainProps, MainState> implements SearchFragmentListener, OnListItemsListener<Favorite> {

  private readonly auth = getAuth();
  private readonly favorites = Favorites.getInstance();

  protected onCreateState(): MainState {
    return {
      ...super.onCreateState(),
      selectedTab: Tab.TOP_PICKS,
    };
  }

  static nestedPaths(): PathComponent[] {
    return [];
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const user = await UserCache.getInstance().getUser(this.auth.currentUser.uid);
    this.setState({
      user: user,
    });

    await this.updateFavoriteStations();
  }

  private autoTurnOffTimeout: any;

  componentDidMount() {
    super.componentDidMount();
    this.favorites.registerObserver(this);
    const audio = App.getAppRefs().audio;
    audio.addEventListener("play", () => {
      this.setState({
        playing: true,
      });
      clearTimeout(this.autoTurnOffTimeout);
      const autoTurnOffHours = Settings.getInstance().autoTurnOffHours;
      if (autoTurnOffHours > 0) {
        this.autoTurnOffTimeout = setTimeout(() => {
          const audio = App.getAppRefs().audio;
          audio.pause();
        }, autoTurnOffHours * ONE_HOUR_IN_SECONDS * SECOND_IN_MILLIS);
      }
    });
    audio.addEventListener("pause", () => {
      this.setState({
        playing: false,
      });
    });
  }

  componentWillUnmount() {
    this.favorites.unregisterObserver(this);
    super.componentWillUnmount();
  }

  componentDidUpdate(prevProps: Readonly<MainProps>, prevState: Readonly<MainState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.selectedStation !== this.state.selectedStation) {
      const audio = App.getAppRefs().audio;
      const src = this.state.selectedStation?.streamUrl;
      if (src) {
        audio.src = src;
        audio.play();
      } else {
        audio.pause();
      }
    }
  }

  onItemChanged(item: Favorite, change: ListItemChange) {
    this.updateFavoriteStations();
  }

  private async updateFavoriteStations() {
    const stations = Stations.getInstance();
    const favorites = await this.favorites.getOrLoadListItems();
    const favoriteStations: Station[] = [];
    for (const item of favorites) {
      const station = await stations.getStationById(item.stationId);
      if (station) {
        favoriteStations.push(station);
      }
    }
    this.setState({
      favoriteStations: favoriteStations,
    });
  }

  renderContent(): React.ReactElement {
    const selectedStation = this.state.selectedStation;
    const hasStation = Boolean(selectedStation?.streamUrl);
    return <>
      <StyledBoxRow style={{
        boxSizing: "border-box",
        flexShrink: 0,
        height: 72,
        padding: PD_MD,
        gap: PD_XLG,
      }}>
        <Logo/>
        {TABS_KTM.values.map(value => <ButtonBase style={{position: "relative"}}
                                                  onClick={() => this.setState({selectedTab: value.key as Tab})}>
          <TabView tabKey={value.key} selected={value.key === this.state.selectedTab}/>
        </ButtonBase>)}
        <StyledSpan/>
        <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
          <ButtonBase
            onClick={() => App.CONTEXT.showDialog(
              null,
              () => <UserFragment uid={this.auth.currentUser.uid}/>)}
          >
            <img src={UserProfilePhoto(this.state.user)} style={{width: "100%", height: "100%"}}/>
          </ButtonBase>
        </Card>
      </StyledBoxRow>
      <StyledBoxColumn style={{padding: PD_XLG, flexGrow: 1, overflowY: "scroll"}}>
        {this.renderTabContent()}
      </StyledBoxColumn>
      <div style={{
        boxSizing: "border-box",
        flexShrink: 0,
        height: 72,
        padding: PD_XSM,
        alignItems: "center",
      }}>
        <Grid container style={{width: "100%"}}>
          <Grid item xs={12} md={9}>
            <StyledBoxRow
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                gap: PD_MD,
                paddingLeft: PD_MD,
              }}>
              <ButtonBase
                disabled={!hasStation}
                style={{opacity: hasStation ? 1 : .5}}
                onClick={() => {
                  const audio = App.getAppRefs().audio;
                  if (this.state.playing) {
                    audio.pause();
                  } else {
                    audio.play();
                  }
                }}>
                {this.state.playing
                  ? <StopCircleOutlined style={{width: 40, height: 40}}/>
                  : <PlayCircleOutlineOutlined style={{width: 40, height: 40}}/>}
              </ButtonBase>
              <StyledBoxRow style={{flexGrow: 1, position: "relative", padding: PD_SM, alignItems: "center"}}>
                <div style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  background: "black",
                  opacity: 0.1,
                  borderRadius: BORDER_RADIUS,
                }}/>
                <StationImage station={selectedStation} style={{width: 48, height: 48}}/>
                <Typography
                  style={{fontSize: "120%", fontWeight: "bold"}}>{selectedStation?.name}
                </Typography>
                <StyledSpan/>
                <ButtonBase
                  disabled={!hasStation}
                  style={{opacity: hasStation ? 1 : .5}}
                  onClick={() => {
                    if (this.state.favorite) {
                      const favorite = this.favorites.getListItems().find(favorite => favorite.stationId === selectedStation.id);
                      if (favorite) {
                        this.favorites.deleteListItemById(favorite.id)
                          .then(() => this.setState({
                            favorite: false,
                          }))
                          .then(() => BaseApp.CONTEXT.showToast("Station removed from Favorites"));
                      }
                    } else {
                      this.favorites.addListItem(Favorite.createNew(selectedStation.id))
                        .then(() => this.setState({
                          favorite: true,
                        }))
                        .then(() => BaseApp.CONTEXT.showToast("Station added to Favorites"));
                    }
                  }}>
                  {this.state.favorite
                    ? <FavoriteOutlined style={{width: 40, height: 40, color: colorEarthRed}}/>
                    : <FavoriteBorderOutlined style={{width: 40, height: 40}}/>}
                </ButtonBase>
                <ButtonBase
                  disabled={!hasStation}
                  style={{opacity: hasStation ? 1 : .5}}
                  onClick={() => {
                    BaseApp.CONTEXT.showDialog({},
                      () => <StyledBoxColumn
                        style={{alignItems: "center", gap: PD_LG, padding: PD_LG, maxWidth: DW_XS}}>
                        <StationImage station={selectedStation} style={{width: 240, aspectRatio: 1}}/>
                        <Typography style={{textAlign: "center"}} variant="h6">{selectedStation.name}</Typography>
                        <Typography style={{textAlign: "center"}}>{selectedStation.description}</Typography>
                      </StyledBoxColumn>,
                    );
                  }}>
                  <InfoOutlined style={{width: 40, height: 40}}/>
                </ButtonBase>
              </StyledBoxRow>
            </StyledBoxRow>
          </Grid>
          <Grid item xs={0} md={3}>
            <div style={{
              height: "100%",
              alignSelf: "center",
              flexGrow: 1,
              background: "url(/images/dot.png) repeat"
            }}/>
          </Grid>
        </Grid>
      </div>
    </>
  }

  private renderTabContent() {
    switch (this.state.selectedTab) {
      default:
      case Tab.TOP_PICKS:
        return this.renderTabContentTopPicks();
      case Tab.FAVORITES:
        return this.renderTabContentFavorites();
      case Tab.FIND_STATION:
        return this.renderTabContentFindStation();
      case Tab.SETTINGS:
        return this.renderTabContentSettings();
    }
  }

  private renderTabContentTopPicks() {
    return this.renderStations(TOP_PICKS_STATIONS);
  }

  private renderTabContentFavorites() {
    if (!(this.state.favoriteStations?.length > 0)) {
      return <StyledEmpty emptyConfig={{
        title: "No Favorite Stations",
        text: "All you Favorite stations will show here. Tap the heart button to mark a station as favorite.",
        iconType: RadioOutlined,
      }}/>;
    }
    return this.renderStations(this.state.favoriteStations);
  }

  private renderTabContentFindStation() {
    return <SearchFragment listener={this}/>;
  }

  onStationSelected(station: Station): void {
    this.setState({
      selectedStation: station,
      favorite: Boolean(this.favorites.getListItems().find(favorite => favorite.stationId === station.id)),
    });
  }

  private renderTabContentSettings() {
    const settings = Settings.getInstance();
    return <StyledContainer>
      <FormGenContainer content={settings} autoSave onContainerSave={() => settings.save()}/>
    </StyledContainer>;
  }

  private renderStations(stationBases: StationBase[]): ReactElement {
    return <Grid container spacing={4}>
      {stationBases.map(stationBase => this.renderStation(stationBase))}
    </Grid>;
  }

  private renderStation(stationBase: StationBase): ReactElement {
    const station: Station = stationBase.type === "station" ? stationBase as Station : null;
    const stationGroup: StationGroup = stationBase.type === "group" ? stationBase as StationGroup : null;
    return <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
      <ButtonBase style={{width: "100%"}} onClick={() => {
        if (station) {
          this.onStationSelected(station);
        } else if (stationGroup) {
          App.CONTEXT.showDialog({}, () => <StyledBoxColumn style={{minWidth: DW_XS, padding: PD_LG}}>
            {stationGroup.list.map(station => <StyledListItem
              onClick={() => {
                App.CONTEXT.hideDialog();
                this.onStationSelected(station);
              }}
              title={station.name} text={station.description} img={station.image}
              defaultImage={"/images/default_station.png"}/>)}
          </StyledBoxColumn>);
        }
      }}>
        <StyledBoxColumn style={{width: "100%"}}>
          <StationImage station={stationBase} style={{aspectRatio: 1, borderRadius: BORDER_RADIUS}}/>
          <Typography
            style={{textAlign: "center", fontWeight: "bold", fontSize: "125%"}}>{stationBase.name}</Typography>
        </StyledBoxColumn>
      </ButtonBase>
    </Grid>;
  }
}
