import {$KTM, BaseListItem, BaseListItemsLoader, KeyMetadatas, TypedObject} from "../shared/types";
import {SvgIcon} from "@mui/material";
import {FavoriteBorderOutlined, SearchOutlined, SettingsOutlined, StarOutlineOutlined} from "@mui/icons-material";
import {nightGray} from "../shared/colors";
import {JsonObject} from "../common/json/json-object";
import {JsonProperty} from "../shared/json/json-property";
import {md5_uuid} from "../shared/md5";
import {getMemberAuth} from "../shared/auth";
import {JSON_OBJECT, Type} from "../shared/json/helpers";
import {Api, ApiStation} from "./api";
import {FormGen} from "../shared/formgen";

export type StationBase = {
  type: "station" | "group",
  id: string,
  name: string,
  image: string,
  websiteUrl?: string,
  supportUrl?: string,
}

export type Station = StationBase & {
  description: string,
  streamUrl: string,
}

export type StationGroup = StationBase & {
  list: Station[],
}

export function stationFromApiStation(station: ApiStation): Station {
  return {
    type: "station",
    id: station.stationuuid,
    name: station.name,
    image: station.favicon,
    description: station.country,
    streamUrl: station.url_resolved,
    websiteUrl: station.homepage,
  };
}

export const TOP_PICKS_STATIONS: (Station | StationGroup)[] = [
  {
    type: "station",
    id: "bff",
    name: "Eclectic Mix",
    image: "/stations/bff.png",
    description: "We're a community radio station streaming live from the heart of San Francisco's Mission District. We showcase up-and-coming artists and underground music, sharing the vibrant Bay Area sound with the world through the power of online radio.",
    streamUrl: "https://stream.bff.fm/1/mp3.mp3",
    websiteUrl: "",
    supportUrl: "https://bff.fm/donate",
  },
  {
    type: "station",
    id: "cinemix",
    name: "Soundtracks",
    image: "/stations/cinemix.png",
    description: "",
    streamUrl: "https://kathy.torontocast.com:1825/stream",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "thecurrent",
    name: "Music",
    image: "/stations/thecurrent.png",
    description: "",
    streamUrl: "https://current.stream.publicradio.org/current.aac",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "dublab",
    name: "Freeform Mix",
    image: "/stations/dublab.png",
    description: "",
    streamUrl: "https://dublab.out.airtime.pro:8000/dublab_a",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "group",
    id: "folkalley",
    name: "Folk",
    image: "/stations/folkalley.png",
    list: [
      {
        type: "station",
        id: "folkalley-main",
        name: "Main",
        image: "https://lh3.ggpht.com/nprQwX4hOA_AbYa9RrrUgBaUzYEkJxojFHFOBj8ERUk7o0jBprQnf7ysOr5sXxFI7A=w300",
        description: "",
        streamUrl: "https://freshgrass.streamguys1.com/folkalley-128mp3",
      },
      {
        type: "station",
        id: "folkalley-freshcuts",
        name: "Fresh Cuts",
        image: "https://folkalley-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/11/26164125/freshcuts-logo.jpg",
        description: "",
        streamUrl: "https://freshgrass.streamguys1.com/freshcuts-128mp3",
      },
      {
        type: "station",
        id: "folkalley-classic",
        name: "Classic",
        image: "https://folkalley-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/11/26163634/classicfolk.stream750x300-1.jpg",
        description: "",
        streamUrl: "https://freshgrass.streamguys1.com/ss1-128mp3",
      },
      {
        type: "station",
        id: "folkalley-irish",
        name: "Irish",
        image: "https://folkalley-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/11/26163921/IrishStream.jpg",
        description: "",
        streamUrl: "https://freshgrass.streamguys1.com/irish-128mp3",
      },
    ],
  },
  {
    type: "group",
    id: "kcrw",
    name: "News, Talk & Music",
    image: "/stations/kcrw.png",
    list: [
      {
        type: "station",
        id: "kcrw-music",
        name: "Music",
        image: "https://www.kcrw.com/music/shows/eclectic24/@@images/square_image/listing-square",
        description: "",
        streamUrl: "https://streams.kcrw.com/e24_mp3",
      },
      {
        type: "station",
        id: "kcrw-live",
        name: "Live",
        image: "https://www.kcrw.com/++theme++kcrw.theme/icons/apple-touch-icon.png",
        description: "",
        streamUrl: "https://streams.kcrw.com/kcrw_mp3",
      },
    ],
  },
  {
    type: "station",
    id: "kcsn",
    name: "Music",
    image: "/stations/kcsn.png",
    description: "",
    streamUrl: "https://stream.885fm.org/1",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "kexp",
    name: "Music",
    image: "/stations/kexp.png",
    description: "",
    streamUrl: "https://live-aacplus-64.kexp.org/kexp64.aac",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "kqed",
    name: "News & Talk",
    image: "/stations/kqed.png",
    description: "",
    streamUrl: "https://streams.kqed.org/kqedradio.m3u",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "kusf",
    name: "Music",
    image: "/stations/kusf.png",
    description: "",
    streamUrl: "https://kusf.stream.creek.fm/stream",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "kutx",
    name: "Music",
    image: "/stations/kutx.png",
    description: "",
    streamUrl: "https://streams.kut.org/4428_192.mp3",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "kuvo",
    name: "Jazz",
    image: "/stations/kuvo.png",
    description: "",
    streamUrl: "https://kuvo-ice.streamguys.org/kuvo-aac-128",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "kxry",
    name: "Music",
    image: "/stations/kxry.png",
    description: "",
    streamUrl: "https://listen.xray.fm:8000/stream",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "group",
    id: "nts",
    name: "Mixes",
    image: "/stations/nts.png",
    list: [
      {
        type: "station",
        id: "",
        name: "",
        image: "",
        description: "",
        streamUrl: "",
      },
    ],
  },
  {
    type: "station",
    id: "rfb",
    name: "Music & News",
    image: "/stations/rfb.png",
    description: "",
    streamUrl: "https://us1.internet-radio.com:8155/live",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "group",
    id: "rpd",
    name: "Music",
    image: "/stations/rpd.jpg",
    list: [
      {
        type: "station",
        id: "",
        name: "",
        image: "",
        description: "",
        streamUrl: "",
      },
    ],
  },
  {
    type: "group",
    id: "somafm",
    name: "Music",
    image: "/stations/somafm.jpg",
    list: [
      {
        type: "station",
        id: "somafm-groove-salad",
        name: "Groove Salad",
        image: "https://somafm.com/img3/groovesalad-400.jpg",
        description: "",
        streamUrl: "https://ice3.somafm.com/groovesalad-128-mp3",
      },
      {
        type: "station",
        id: "somafm-space-station",
        name: "Space Station",
        image: "https://somafm.com/img3/spacestation-400.jpg",
        description: "",
        streamUrl: "https://ice5.somafm.com/spacestation-128-aac",
      },
      {
        type: "station",
        id: "somafm-indie-pop",
        name: "Indie Pop",
        image: "https://somafm.com/img3/indiepop-400.jpg",
        description: "",
        streamUrl: "https://ice3.somafm.com/indiepop-128-aac",
      },
      {
        type: "station",
        id: "somafm-heavyweight-reggae",
        name: "Heavyweight Reggae",
        image: "https://somafm.com/img3/reggae400.png",
        description: "",
        streamUrl: "https://ice2.somafm.com/reggae-128-aac",
      },
      // {
      //   type: "station",
      //   id: "",
      //   name: "",
      //   image: "",
      //   description: "",
      //   streamUrl: "",
      // },
      // {
      //   type: "station",
      //   id: "",
      //   name: "",
      //   image: "",
      //   description: "",
      //   streamUrl: "",
      // },
      // {
      //   type: "station",
      //   id: "",
      //   name: "",
      //   image: "",
      //   description: "",
      //   streamUrl: "",
      // },
      // {
      //   type: "station",
      //   id: "",
      //   name: "",
      //   image: "",
      //   description: "",
      //   streamUrl: "",
      // },
    ],
  },
  {
    type: "station",
    id: "wamu",
    name: "News & Talk",
    image: "/stations/wamu.png",
    description: "",
    streamUrl: "https://wamu.cdnstream1.com/wamu.mp3",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "wbgo",
    name: "Jazz",
    image: "/stations/wbgo.png",
    description: "",
    streamUrl: "https://wbgo.streamguys.net/wbgo",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "group",
    id: "wfmu",
    name: "Music",
    image: "/stations/wfmu.png",
    list: [
      {
        type: "station",
        id: "",
        name: "",
        image: "",
        description: "",
        streamUrl: "",
      },
    ],
  },
  {
    type: "station",
    id: "wfuv",
    name: "Music",
    image: "/stations/wfuv.png",
    description: "",
    streamUrl: "https://wfuv-onair.streamguys.org/onair-hi",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "wmot",
    name: "Americana",
    image: "/stations/wmot.png",
    description: "",
    streamUrl: "https://ice64.securenetsystems.net/WMOT",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "station",
    id: "wnyc",
    name: "News & Talk",
    image: "/stations/wnyc.png",
    description: "",
    streamUrl: "https://fm939.wnyc.org/wnycfm",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "group",
    id: "wqxr",
    name: "Classics",
    image: "/stations/wqxr.png",
    list: [
      {
        type: "station",
        id: "",
        name: "",
        image: "",
        description: "",
        streamUrl: "",
      },
    ],
  },
  {
    type: "station",
    id: "wwoz",
    name: "Jazz",
    image: "/stations/wwoz.png",
    description: "",
    streamUrl: "https://wwoz-sc.streamguys.com/wwoz-hi.mp3",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "group",
    id: "wxpn",
    name: "Music",
    image: "/stations/wxpn.png",
    list: [
      {
        type: "station",
        id: "",
        name: "",
        image: "",
        description: "",
        streamUrl: "",
      },
    ],
  },
  {
    type: "station",
    id: "wxyc",
    name: "Eclectic Mix",
    image: "/stations/wxyc.png",
    description: "",
    streamUrl: "https://audio-mp3.ibiblio.org:8000/wxyc.mp3",
    websiteUrl: "",
    supportUrl: "",
  },
  {
    type: "group",
    id: "abc",
    name: "Australia",
    image: "/stations/abc.png",
    list: [
      {
        type: "station",
        id: "",
        name: "",
        image: "",
        description: "",
        streamUrl: "",
      },
    ],
  },
  {
    type: "group",
    id: "bbc",
    name: "UK",
    image: "/stations/bbc.png",
    list: [
      {
        type: "station",
        id: "bbc-radio-1",
        name: "BBC Radio 1",
        image: "https://cdn-radiotime-logos.tunein.com/s24939q.png",
        description: "",
        streamUrl: "https://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_radio_one/bbc_radio_one.isml/bbc_radio_one-audio%3d96000.norewind.m3u8",
        websiteUrl: "https://www.bbc.co.uk/radio1",
      },
      {
        type: "station",
        id: "bbc-radio-1xtra",
        name: "BBC Radio 1Xtra",
        image: "https://firebasestorage.googleapis.com/v0/b/radiogalaxy-580f4.appspot.com/o/images%2FIMG_20240224_041254637.jpg?alt=media&token=a405586a-a4f6-49dc-b6dd-1d94b7c145ea",
        description: "",
        streamUrl: "https://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_1xtra/bbc_1xtra.isml/bbc_1xtra-audio=320000.m3u8",
      },
      {
        type: "station",
        id: "bbc-radio-2",
        name: "BBC Radio 2",
        image: "https://cdn-radiotime-logos.tunein.com/s24940q.png",
        description: "",
        streamUrl: "https://lstn.lv/bbc.m3u8?station=bbc_radio_two&bitrate=320000",
      },
      {
        type: "station",
        id: "bbc-radio-3",
        name: "BBC Radio 3",
        image: "https://cdn-radiotime-logos.tunein.com/s24941q.png",
        description: "",
        streamUrl: "https://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_radio_three/bbc_radio_three.isml/bbc_radio_three-audio=320000.m3u8",
      },
      {
        type: "station",
        id: "bbc-radio-4",
        name: "BBC Radio 4",
        image: "https://cdn-radiotime-logos.tunein.com/s25419q.png",
        description: "",
        streamUrl: "https://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_radio_fourfm/bbc_radio_fourfm.isml/bbc_radio_fourfm-audio=320000.m3u8",
      },
      {
        type: "station",
        id: "bbc-radio-6-music",
        name: "BBC Radio 6 Music",
        image: "https://cdn-radiotime-logos.tunein.com/s44491q.png",
        description: "",
        streamUrl: "https://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_6music/bbc_6music.isml/bbc_6music-audio=320000.m3u8",
      },
      {
        type: "station",
        id: "bbc-asian-network",
        name: "BBC Asian Network",
        image: "https://cdn-radiotime-logos.tunein.com/s44490q.png",
        description: "",
        streamUrl: "https://as-hls-ww-live.akamaized.net/pool_904/live/ww/bbc_asian_network/bbc_asian_network.isml/bbc_asian_network-audio%3d96000.norewind.m3u8",
      },
    ],
  },
  {
    type: "group",
    id: "fip",
    name: "France",
    image: "/stations/fip.png",
    list: [
      {
        type: "station",
        id: "fip-direct",
        name: "Direct",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/FIP_logo_2021.svg/2048px-FIP_logo_2021.svg.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fip-hifi.aac",
      },
      {
        type: "station",
        id: "fip-rock",
        name: "Rock",
        image: "https://www.radiofrance.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fiprock-hifi.aac",
      },
      {
        type: "station",
        id: "fip-jazz",
        name: "Jazz",
        image: "https://www.radiofrance.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fipjazz-hifi.aac",
      },
      {
        type: "station",
        id: "fip-groove",
        name: "Groove",
        image: "https://www.radiofrance.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fipgroove-hifi.aac",
      },
      {
        type: "station",
        id: "fip-metal",
        name: "Metal",
        image: "https://www.radiofrance.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fipmetal-hifi.aac",
        websiteUrl: "https://www.radiofrance.fr/fip/radio-metal",
      },
      {
        type: "station",
        id: "fip-hip-hop",
        name: "Hip-hop",
        image: "https://www.radiofrance.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fiphiphop-hifi.aac",
        websiteUrl: "https://www.radiofrance.fr/fip/radio-hip-hop",
      },
      {
        type: "station",
        id: "fip-pop",
        name: "Pop",
        image: "https://www.fip.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fippop-hifi.aac",
        websiteUrl: "https://www.radiofrance.fr/fip/radio-pop",
      },
      {
        type: "station",
        id: "fip-electro",
        name: "Electro",
        image: "https://www.fip.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fipelectro-hifi.aac",
      },
      {
        type: "station",
        id: "fip-monde",
        name: "Monde",
        image: "https://www.fip.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fipworld-midfi.mp3",
      },
      {
        type: "station",
        id: "fip-reggae",
        name: "Reggae",
        image: "https://www.fip.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://icecast.radiofrance.fr/fipreggae-hifi.aac",
      },
      {
        type: "station",
        id: "fip-sacre",
        name: "Sacré Français !",
        image: "https://www.radiofrance.fr/dist/favicons/logo-120.png",
        description: "",
        streamUrl: "https://stream.radiofrance.fr/fipsacrefrancais/fipsacrefrancais.m3u8?id=radiofrance",
      },
    ],
  },
];

export enum Tab {
  TOP_PICKS = "top_picks",
  FAVORITES = "favorites",
  FIND_STATION = "find_station",
  SETTINGS = "settings",
}

export type TabMetadata = {
  title: string,
  color: string,
  iconType: typeof SvgIcon,
}
export const TABS_KTM = new KeyMetadatas<TabMetadata>([
  $KTM<TabMetadata>(Tab.TOP_PICKS, {
    title: "Top Picks",
    color: "#be1e2d",
    iconType: StarOutlineOutlined,
  }),
  $KTM<TabMetadata>(Tab.FAVORITES, {
    title: "Favorites",
    color: "#006838",
    iconType: FavoriteBorderOutlined,
  }),
  $KTM<TabMetadata>(Tab.FIND_STATION, {
    title: "Find a Station",
    color: "#fbb040",
    iconType: SearchOutlined,
  }),
  $KTM<TabMetadata>(Tab.SETTINGS, {
    title: "Settings",
    color: nightGray,
    iconType: SettingsOutlined,
  }),
])

export class Stations {

  private static instance: Stations;

  static getInstance(): Stations {
    if (!this.instance) {
      this.instance = new Stations();
    }
    return this.instance;
  }

  async getStationById(id: string): Promise<Station> {
    for (const base of TOP_PICKS_STATIONS) {
      if (base.type === "station" && base.id === id) {
        return base as Station;
      } else if (base.type === "group") {
        for (const station of (base as StationGroup).list) {
          if (station.id === id) {
            return station;
          }
        }
      }
    }
    const apiStation = await Api.getInstance().searchRadioBrowserStationByUuid(id);
    if (apiStation) {
      return stationFromApiStation(apiStation);
    }
    return null;
  }
}

@JsonObject()
export class Favorite extends BaseListItem {

  @JsonProperty()
  readonly stationId: string;

  static createNew(stationId: string): Favorite {
    return new Favorite(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), stationId);
  }

  constructor(id: string, creator: string, created: number, stationId: string) {
    super(id, creator, created);
    this.stationId = stationId;
  }
}

export class Favorites extends BaseListItemsLoader<Favorite> {

  private static instance: Favorites;

  static getInstance(): Favorites {
    if (!this.instance) {
      this.instance = new Favorites();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "favorites";
  }

  async addListItem(item: Favorite): Promise<void> {
    return super.addListItem(item);
  }

  protected deserializeItem(value: any): Favorite {
    return JSON_OBJECT.deserializeObject(value, Favorite);
  }

  protected serializeItem(item: Favorite): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Favorite, item2: Favorite): number {
    return 0;
  }
}

@JsonObject()
export class Settings extends TypedObject {

  private static instance: Settings;

  static getInstance(): Settings {
    return this.instance;
  }

  static async loadInstance() {
    this.instance = await Settings.load();
  }

  private readonly memberAuth = getMemberAuth();

  readonly id: string = "settings";

  @FormGen({
    name: "Automatically turn off after (hours)",
    description: "Turns off playback automatically after specified number of hours.",
    type: "number"
  })
  @JsonProperty()
  autoTurnOffHours: number = 0;

  constructor() {
    super(null, null);
  }

  static async load() {
    const obj = new Settings();
    if (!obj.memberAuth) {
      return null;
    }
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return Settings;
  }
}