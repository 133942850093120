import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../shared/BaseFragment";
import React, {ReactElement} from "react";
import {CircularProgress, TextField, Typography} from "@mui/material";
import {StyledBoxColumn, StyledContainer, StyledListItem} from "../shared/StyledComponents";
import {Debouncer} from "../shared/file_util";
import {Api, ApiStation} from "./api";
import {Station, stationFromApiStation} from "./types";

export interface SearchFragmentListener {
  onStationSelected(station: Station): void;
}

export type SearchFragmentProps = BaseFragmentProps & {
  listener: SearchFragmentListener,
}

export type SearchResult = {
  station: ApiStation,
  image: string,
  title: string,
  text: string,
}

type SearchFragmentState = BaseFragmentState & {
  searchText?: string,
  searching?: boolean,
  searchResults?: SearchResult[],
}

export class SearchFragment extends BaseFragment<SearchFragmentProps, SearchFragmentState> {

  private readonly debouncer = new Debouncer();

  componentDidUpdate(prevProps: Readonly<SearchFragmentProps>, prevState: Readonly<SearchFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.searchText !== this.state.searchText) {
      if (this.state.searchText?.length >= 3) {
        this.setState({
          searching: true,
        });
        this.debouncer.run(() => {
          Api.getInstance().searchRadioBrowserStationByName(this.state.searchText)
            .then(apiStations => {
              this.setState({
                searching: false,
                searchResults: apiStations.map(station => ({
                  station: station,
                  image: station.favicon,
                  title: station.name,
                  text: station.country,
                })),
              });
            });
        });
      } else {
        this.setState({
          searching: false,
          searchResults: [],
        });
      }
    }
  }

  protected renderContainerContent(): ReactElement | null {
    return <StyledContainer>
      <TextField
        placeholder={"Type to search..."}
        sx={{
          input: {
            background: "white",
          },
        }}
        value={this.state.searchText}
        onChange={event => this.setState({searchText: event.target.value})}
      />
      {this.state.searchText?.length >= 3
        ? (!this.state.searching
          ? (this.state.searchResults?.length > 0
            ? <StyledBoxColumn style={{}}>
              {this.state.searchResults?.map(result => {
                return <StyledListItem
                  onClick={() => this.props.listener.onStationSelected(stationFromApiStation(result.station))}
                  defaultImage={"/images/default_station.png"}
                  title={result.title}
                  text={result.text}
                  img={result.image}/>;
              })}
            </StyledBoxColumn>
            : <Typography>No results found.</Typography>)
          : <CircularProgress/>)
        : <Typography>Type above to search for a station by name, call letters, its city, or the type of music it
          plays.</Typography>
      }
    </StyledContainer>;
  }
}