import {JsonObject} from "../shared/json/json-object";
import {JsonProperty} from "../shared/json/json-property";
import {JSON_OBJECT} from "../shared/json/helpers";
import {LocalCache} from "../shared/LocalCache";
import {md5} from "../shared/md5";

@JsonObject()
export class ApiStation {

  @JsonProperty()
  readonly changeuuid: string;
  @JsonProperty()
  readonly stationuuid: string;
  @JsonProperty()
  readonly serveruuid: string;
  @JsonProperty()
  readonly name: string;
  @JsonProperty()
  readonly url: string;
  @JsonProperty()
  readonly url_resolved: string;
  @JsonProperty()
  readonly homepage: string;
  @JsonProperty()
  readonly favicon: string;
  @JsonProperty()
  readonly tags: string;
  @JsonProperty()
  readonly country: string;
  @JsonProperty()
  readonly countrycode: string;
  @JsonProperty()
  readonly iso_3166_2: string;
  @JsonProperty()
  readonly state: string;
  @JsonProperty()
  readonly language: string;
  @JsonProperty()
  readonly languagecodes: string;
  @JsonProperty()
  readonly votes: number;
  @JsonProperty()
  readonly lastchangetime: string;
  @JsonProperty()
  readonly lastchangetime_iso8601: string;
  @JsonProperty()
  readonly codec: string;
  @JsonProperty()
  readonly bitrate: number;
  @JsonProperty()
  readonly hls: number;
  @JsonProperty()
  readonly lastcheckok: number;
  @JsonProperty()
  readonly lastchecktime: string;
  @JsonProperty()
  readonly lastchecktime_iso8601: string;
  @JsonProperty()
  readonly lastcheckoktime: string;
  @JsonProperty()
  readonly lastcheckoktime_iso8601: string;
  @JsonProperty()
  readonly lastlocalchecktime: string;
  @JsonProperty()
  readonly lastlocalchecktime_iso8601: string;
  @JsonProperty()
  readonly clicktimestamp: string;
  @JsonProperty()
  readonly clicktimestamp_iso8601: string;
  @JsonProperty()
  readonly clickcount: number;
  @JsonProperty()
  readonly clicktrend: number;
  @JsonProperty()
  readonly ssl_error: number;
  @JsonProperty()
  readonly geo_lat: number;
  @JsonProperty()
  readonly geo_long: number;
  @JsonProperty()
  readonly has_extended_info: boolean;

  constructor(changeuuid: string, stationuuid: string, serveruuid: string, name: string, url: string, url_resolved: string, homepage: string, favicon: string, tags: string, country: string, countrycode: string, iso_3166_2: string, state: string, language: string, languagecodes: string, votes: number, lastchangetime: string, lastchangetime_iso8601: string, codec: string, bitrate: number, hls: number, lastcheckok: number, lastchecktime: string, lastchecktime_iso8601: string, lastcheckoktime: string, lastcheckoktime_iso8601: string, lastlocalchecktime: string, lastlocalchecktime_iso8601: string, clicktimestamp: string, clicktimestamp_iso8601: string, clickcount: number, clicktrend: number, ssl_error: number, geo_lat: number, geo_long: number, has_extended_info: boolean) {
    this.changeuuid = changeuuid;
    this.stationuuid = stationuuid;
    this.serveruuid = serveruuid;
    this.name = name;
    this.url = url;
    this.url_resolved = url_resolved;
    this.homepage = homepage;
    this.favicon = favicon;
    this.tags = tags;
    this.country = country;
    this.countrycode = countrycode;
    this.iso_3166_2 = iso_3166_2;
    this.state = state;
    this.language = language;
    this.languagecodes = languagecodes;
    this.votes = votes;
    this.lastchangetime = lastchangetime;
    this.lastchangetime_iso8601 = lastchangetime_iso8601;
    this.codec = codec;
    this.bitrate = bitrate;
    this.hls = hls;
    this.lastcheckok = lastcheckok;
    this.lastchecktime = lastchecktime;
    this.lastchecktime_iso8601 = lastchecktime_iso8601;
    this.lastcheckoktime = lastcheckoktime;
    this.lastcheckoktime_iso8601 = lastcheckoktime_iso8601;
    this.lastlocalchecktime = lastlocalchecktime;
    this.lastlocalchecktime_iso8601 = lastlocalchecktime_iso8601;
    this.clicktimestamp = clicktimestamp;
    this.clicktimestamp_iso8601 = clicktimestamp_iso8601;
    this.clickcount = clickcount;
    this.clicktrend = clicktrend;
    this.ssl_error = ssl_error;
    this.geo_lat = geo_lat;
    this.geo_long = geo_long;
    this.has_extended_info = has_extended_info;
  }
}

export class Api {
  private static instance: Api;

  static getInstance(): Api {
    if (!this.instance) {
      this.instance = new Api();
    }
    return this.instance;
  }

  /**
   * Ask a specified server for a list of all other server.
   */
  private async getRadioBrowserBaseUrls(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      // TODO: all.api.radio-browser.info doesn't support https yet. Check if it does and invoke this at runtime.
      // const request = new XMLHttpRequest()
      // // If you need https, you have to use fixed servers, at best a list for this request
      // request.open('GET', 'http://all.api.radio-browser.info/json/servers', true);
      // request.onload = function () {
      //   if (request.status >= 200 && request.status < 300) {
      //     const items = JSON.parse(request.responseText).map(x => "https://" + x.name);
      //     resolve(items);
      //   } else {
      //     reject(request.statusText);
      //   }
      // }
      // request.send();
      const items = JSON.parse("[\n" +
        "  {\n" +
        "    \"ip\": \"2a01:4f9:c011:bc25::1\",\n" +
        "    \"name\": \"nl1.api.radio-browser.info\"\n" +
        "  },\n" +
        "  {\n" +
        "    \"ip\": \"2a03:4000:37:42:c4fe:4cff:fea7:8941\",\n" +
        "    \"name\": \"de1.api.radio-browser.info\"\n" +
        "  },\n" +
        "  {\n" +
        "    \"ip\": \"2a0a:4cc0:0:db9:282b:91ff:fed0:ddea\",\n" +
        "    \"name\": \"at1.api.radio-browser.info\"\n" +
        "  },\n" +
        "  {\n" +
        "    \"ip\": \"65.109.136.86\",\n" +
        "    \"name\": \"nl1.api.radio-browser.info\"\n" +
        "  },\n" +
        "  {\n" +
        "    \"ip\": \"89.58.16.19\",\n" +
        "    \"name\": \"at1.api.radio-browser.info\"\n" +
        "  },\n" +
        "  {\n" +
        "    \"ip\": \"91.132.145.114\",\n" +
        "    \"name\": \"de1.api.radio-browser.info\"\n" +
        "  }\n" +
        "]").map(x => "https://" + x.name);
      resolve(items);
    });
  }

  /**
   * Ask a server for its settings.
   */
  getRadioBrowserServerConfig(baseurl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var request = new XMLHttpRequest()
      request.open('GET', baseurl + '/json/config', true);
      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          const items = JSON.parse(request.responseText);
          resolve(items);
        } else {
          reject(request.statusText);
        }
      }
      request.send();
    });
  }

  /**
   * Get a random available radio-browser server.
   * Returns: string - base url for radio-browser api
   */
  async getRadioBrowserBaseUrlRandom(): Promise<string> {
    const hosts = await this.getRadioBrowserBaseUrls();
    return hosts[Math.floor(Math.random() * hosts.length)];
  }

  private readonly cache = LocalCache.getInstance("api");
  private baseUrl: string;

  async fetchResults(path: string): Promise<string> {
    const key = md5(path);
    let value = this.cache.get(key);
    if (!value) {
      let retryCount = 3;
      do {
        if (!this.baseUrl) {
          this.baseUrl = await this.getRadioBrowserBaseUrlRandom();
        }
        try {
          const response = await fetch(this.baseUrl + path);
          if (response.status >= 200 && response.status < 300) {
            value = await response.text();
            this.cache.set(key, value);
          }
          break;
        } catch (e) {
          this.baseUrl = null;
        }
      } while (--retryCount > 0);
    }
    return value || "[]";
  }

  async searchRadioBrowserStationByName(name: string): Promise<ApiStation[]> {
    const items = JSON_OBJECT.deserializeObjectArray(await this.fetchResults('/json/stations/byname/' + name), ApiStation);
    items.sort((station1, station2) => station2.votes - station1.votes);
    return items;
  }

  async searchRadioBrowserStationByUuid(uuid: string): Promise<ApiStation> {
    const items = JSON_OBJECT.deserializeObjectArray(await this.fetchResults('/json/stations/byuuid/' + uuid), ApiStation);
    return items?.[0];
  }
}