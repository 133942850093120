import {Prefs} from "./prefs";
import {JsonObject} from "./json/json-object";
import {JsonProperty} from "./json/json-property";
import {JSON_OBJECT} from "./json/helpers";

@JsonObject()
class CacheValue {

  @JsonProperty()
  readonly value: string;

  @JsonProperty()
  readonly expiresAt: number;

  constructor(value: string, expiresAt: number) {
    this.value = value;
    this.expiresAt = expiresAt;
  }
}

export class LocalCache {

  private static readonly instances = new Map<string, LocalCache>();

  static getInstance(name: string, expirySeconds: number = 3600): LocalCache {
    let cache = this.instances.get(name);
    if (!cache) {
      cache = new LocalCache(name, expirySeconds);
      this.instances.set(name, cache);
    }
    return cache;
  }

  private readonly prefs;

  private constructor(name: string, private readonly expirySeconds: number) {
    this.prefs = new Prefs(name);
  }

  clear() {
    this.prefs.clear();
  }

  set(key: string, value: string) {
    this.prefs.setString(key, JSON.stringify(JSON_OBJECT.serializeObject(new CacheValue(value, Date.now() + this.expirySeconds * 1000))));
  }

  get(key: string): string | null {
    const str = this.prefs.getString(key);
    if (!str) {
      return null;
    }
    try {
      const cacheValue = JSON_OBJECT.deserializeObject(JSON.parse(str), CacheValue);
      if (cacheValue?.expiresAt > Date.now()) {
        return cacheValue.value;
      }
    } catch (e) {
      // Continue.
    }
    return null;
  }
}