// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyD4PW-EkfMYF5t-Uq7vft3jreIGksPzCOs",
//   authDomain: "plugins-playground-7971a.firebaseapp.com",
//   projectId: "plugins-playground-7971a",
//   storageBucket: "plugins-playground-7971a.appspot.com",
//   messagingSenderId: "66818322724",
//   appId: "1:66818322724:web:c03ec9054d26ffb40e83b1",
//   measurementId: "G-H0VZ9TTFMY"
// };

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBNmOI1Nc7pf02draMAwfdTCJCCrmQol74",
  authDomain: "fynestudio-apps.firebaseapp.com",
  databaseURL: "https://fynestudio-apps-default-rtdb.firebaseio.com",
  projectId: "fynestudio-apps",
  storageBucket: "fynestudio-apps.appspot.com",
  messagingSenderId: "820717950395",
  appId: "1:820717950395:web:3d5f88aaa33699b5c4a09e",
  measurementId: "G-6LNJL3HBHY"
};